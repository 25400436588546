const Dict = require(`nllib/src/dict`);

const dict_app_environment_join = new Dict({
    initType: `000`,
    initParameter: {
        '000': {
            entityDefine: {
                text: `text`,
                value: `value`
            },
            entityList: [

                //----------frontend------------------------------------------------------------------------------------

                //<editor-fold desc="frontend-distributor">
                {
                    environment: `local`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `//alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `//alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `//beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `//beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `https://b.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `https://b-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-distributor`,
                    urlPrefix: `//omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-platform">
                {
                    environment: `local`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-platform`,
                    urlPrefix: `//platform-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-supplier">
                {
                    environment: `local`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-supplier`,
                    urlPrefix: `//gy-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-b2c-mall">
                {
                    environment: `local`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-b2c-mall`,
                    urlPrefix: `//b2c-mall-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-b2c-admin">
                {
                    environment: `local`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-b2c-admin`,
                    urlPrefix: `//b2c-admin-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-haolv-enterprise">
                {
                    environment: `local`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//alpha.wholeok.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//alpha.wholeok.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//beta.wholeok.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//beta-001.wholeok.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//www.wholeok.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//www-001.wholeok.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-haolv-enterprise`,
                    urlPrefix: `//omega.wholeok.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-haolv-enterprise-app">
                {
                    environment: `local`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-alpha.wholeok.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-alpha.wholeok.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-beta.wholeok.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-beta-001.wholeok.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app.wholeok.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-001.wholeok.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-haolv-enterprise-app`,
                    urlPrefix: `//app-omega.wholeok.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-haolv-platform">
                {
                    environment: `local`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-alpha.wholeok.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-alpha.wholeok.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-beta.wholeok.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-beta-001.wholeok.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform.wholeok.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-001.wholeok.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-haolv-platform`,
                    urlPrefix: `//platform-omega.wholeok.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-yinzhilv-finance">
                {
                    environment: `local`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-yinzhilv-finance`,
                    urlPrefix: `//caiwu-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-yinzhilv-frontend-platform-flight">
                {
                    environment: `local`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-yinzhilv-frontend-platform-flight`,
                    urlPrefix: `//kefu-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-yinzhilv-edi">
                {
                    environment: `local`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-beta.yinzhilv.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-beta-001.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi.yinzhilv.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-001.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-yinzhilv-edi`,
                    urlPrefix: `//edi-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="frontend-demo-400">
                {
                    environment: `local`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-alpha.yinzhitravel.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-alpha.yinzhitravel.com`,
                },
                {
                    environment: `beta`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-beta.yinzhitravel.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-beta-001.yinzhitravel.com`,
                },
                {
                    environment: `release`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400.yinzhitravel.com`,
                },
                {
                    environment: `release-001`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-001.yinzhitravel.com`,
                },
                {
                    environment: `omega`,
                    appKey: `frontend-demo-400`,
                    urlPrefix: `//demo-400-omega.yinzhitravel.com`,
                },
                //</editor-fold>

                //----------backend-------------------------------------------------------------------------------------

                //<editor-fold desc="default">
                {
                    environment: `local`,
                    appKey: `default`,
                    urlPrefix: `//tour-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `default`,
                    urlPrefix: `//tour-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `default`,
                    urlPrefix: `//tour-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `default`,
                    urlPrefix: `//tour.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `default`,
                    urlPrefix: `//tour-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="tour">
                {
                    environment: `local`,
                    appKey: `tour`,
                    urlPrefix: `//tour-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `tour`,
                    urlPrefix: `//tour-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `tour`,
                    urlPrefix: `//tour-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `tour`,
                    urlPrefix: `//tour.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `tour`,
                    urlPrefix: `//tour-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="hotel">
                {
                    environment: `local`,
                    appKey: `hotel`,
                    urlPrefix: `//219.128.52.2:9090`,
                },
                {
                    environment: `alpha`,
                    appKey: `hotel`,
                    urlPrefix: `//hotel-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `hotel`,
                    urlPrefix: `//hotel-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `hotel`,
                    urlPrefix: `//hotel.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `hotel`,
                    urlPrefix: `//hotel-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="flight">
                {
                    environment: `local`,
                    appKey: `flight`,
                    urlPrefix: `//flight-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `flight`,
                    urlPrefix: `//flight-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `flight`,
                    urlPrefix: `//flight-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `flight`,
                    urlPrefix: `//flight.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `flight`,
                    urlPrefix: `//flight-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="flight-admin">
                {
                    environment: `local`,
                    appKey: `flight-admin`,
                    urlPrefix: `//flight-admin-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `flight-admin`,
                    urlPrefix: `//flight-admin-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `flight-admin`,
                    // urlPrefix: `//flight-beta.yinzhilv.com`,
                    urlPrefix: `//flight-admin-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `flight-admin`,
                    urlPrefix: `//flight-admin.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `flight-admin`,
                    urlPrefix: `//flight-admin-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="flight-search">
                {
                    environment: `local`,
                    appKey: `flight-search`,
                    urlPrefix: `//flight-search-beta.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `flight-search`,
                    urlPrefix: `//flight-search-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `flight-search`,
                    // urlPrefix: `//flight-beta.yinzhilv.com`,
                    urlPrefix: `//flight-search-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `flight-search`,
                    urlPrefix: `//flight-search.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `flight-search`,
                    urlPrefix: `//flight-search-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="monitor">
                {
                    environment: `local`,
                    appKey: `monitor`,
                    urlPrefix: `//219.128.52.2:7799`,
                },
                {
                    environment: `alpha`,
                    appKey: `monitor`,
                    urlPrefix: `//219.128.52.2:7799`,
                },
                {
                    environment: `beta`,
                    appKey: `monitor`,
                    urlPrefix: `//monitortest.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `monitor`,
                    urlPrefix: `//monitor.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `monitor`,
                    urlPrefix: `//monitor-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="payment">
                {
                    environment: `local`,
                    appKey: `payment`,
                    urlPrefix: `//payment-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `payment`,
                    urlPrefix: `//payment-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `payment`,
                    urlPrefix: `//payment-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `payment`,
                    urlPrefix: `//payment.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `payment`,
                    urlPrefix: `//payment-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="wallet">
                {
                    environment: `local`,
                    appKey: `wallet`,
                    // urlPrefix: `//219.128.52.2:7777`,
                    // urlPrefix: `//219.128.52.2:18001`,
                    urlPrefix: `//wallet-alpha.yinzhilv.com/`,
                },
                {
                    environment: `alpha`,
                    appKey: `wallet`,
                    // urlPrefix: `//219.128.52.2:7777`,
                    // urlPrefix: `//219.128.52.2:18001`,
                    urlPrefix: `//wallet-alpha.yinzhilv.com/`,
                },
                {
                    environment: `beta`,
                    appKey: `wallet`,
                    urlPrefix: `//wallettest.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `wallet`,
                    urlPrefix: `//wallet.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `wallet`,
                    urlPrefix: `//wallet-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="trains">
                {
                    environment: `local`,
                    appKey: `trains`,
                    urlPrefix: `//219.128.52.2:9900`,
                },
                {
                    environment: `alpha`,
                    appKey: `trains`,
                    urlPrefix: `//219.128.52.2:9900`,
                },
                {
                    environment: `beta`,
                    appKey: `trains`,
                    urlPrefix: `//traintest.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `trains`,
                    urlPrefix: `//train.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `trains`,
                    urlPrefix: `//train-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="longcunguan">
                {
                    environment: `local`,
                    appKey: `longcunguan`,
                    urlPrefix: `https://lcg.murongtech.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `longcunguan`,
                    urlPrefix: `https://lcg.murongtech.com`,
                },
                {
                    environment: `beta`,
                    appKey: `longcunguan`,
                    urlPrefix: `https://lcg.murongtech.com`,
                },
                {
                    environment: `release`,
                    appKey: `longcunguan`,
                    urlPrefix: `https://lcg.ccb.com`,
                },
                {
                    environment: `omega`,
                    appKey: `longcunguan`,
                    urlPrefix: `https://lcg.murongtech.com`,
                },
                //</editor-fold>

                //<editor-fold desc="UnionPay-b2b">
                {
                    environment: `local`,
                    appKey: `UnionPay-b2b`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `alpha`,
                    appKey: `UnionPay-b2b`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `beta`,
                    appKey: `UnionPay-b2b`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `release`,
                    appKey: `UnionPay-b2b`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `omega`,
                    appKey: `UnionPay-b2b`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                //</editor-fold>

                //<editor-fold desc="UnionPay-b2c">
                {
                    environment: `local`,
                    appKey: `UnionPay-b2c`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `alpha`,
                    appKey: `UnionPay-b2c`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `beta`,
                    appKey: `UnionPay-b2c`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `release`,
                    appKey: `UnionPay-b2c`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                {
                    environment: `omega`,
                    appKey: `UnionPay-b2c`,
                    urlPrefix: `https://payment.chinapay.com/CTITS/service/rest/page/nref/000000000017/0/0/0/0/0`,
                },
                //</editor-fold>

                //<editor-fold desc="haolv-default">
                {
                    environment: `local`,
                    appKey: `haolv-default`,
                    urlPrefix: `//api-alpha.wholeok.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `haolv-default`,
                    urlPrefix: `//api-alpha.wholeok.com`,
                },
                {
                    environment: `beta`,
                    appKey: `haolv-default`,
                    urlPrefix: `//api-beta.wholeok.com`,
                    // urlPrefix: `//api.wholeok.com`,
                },
                {
                    environment: `release`,
                    appKey: `haolv-default`,
                    // urlPrefix: `//api.wholeok.com`,
                    urlPrefix: `//v2-api.wholeok.com`,
                },
                {
                    environment: `omega`,
                    appKey: `haolv-default`,
                    // urlPrefix: `//api-omega.wholeok.com`,
                    urlPrefix: `//v2-api.wholeok.com`,
                },
                {
                    environment: `beta-001`,
                    appKey: `haolv-default`,
                    urlPrefix: `//v2-api.wholeok.com`,
                },
                //</editor-fold>

                //<editor-fold desc="hangsitehui-default">
                {
                    environment: `local`,
                    appKey: `hangsitehui-default`,
                    urlPrefix: `//hangsitehui-api-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `hangsitehui-default`,
                    urlPrefix: `//hangsitehui-api-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `hangsitehui-default`,
                    urlPrefix: `//hangsitehui-api-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `hangsitehui-default`,
                    urlPrefix: `//hangsitehui-api-release.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `hangsitehui-default`,
                    urlPrefix: `//hangsitehui-api-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="statistics">
                {
                    environment: `local`,
                    appKey: `statistics`,
                    urlPrefix: `//statistics-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `statistics`,
                    urlPrefix: `//statistics-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `statistics`,
                    urlPrefix: `//statistics-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `statistics`,
                    urlPrefix: `//statistics.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `statistics`,
                    urlPrefix: `//statistics-beta.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="b2c">
                {
                    environment: `local`,
                    appKey: `b2c`,
                    urlPrefix: `//b2c-api-alpha.yinzhilv.com`,
                },
                {
                    environment: `alpha`,
                    appKey: `b2c`,
                    urlPrefix: `//b2c-api-alpha.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `b2c`,
                    urlPrefix: `//b2c-api-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `b2c`,
                    urlPrefix: `//b2c-api.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `b2c`,
                    urlPrefix: `//b2c-api-omega.yinzhilv.com`,
                },
                //</editor-fold>

                //<editor-fold desc="yinzhilv-temporary-storage-system">
                {
                    environment: `local`,
                    appKey: `yinzhilv-temporary-storage-system`,
                    urlPrefix: `//api-alpha.wholeok.com/report`,
                },
                {
                    environment: `alpha`,
                    appKey: `yinzhilv-temporary-storage-system`,
                    urlPrefix: `//api-beta.wholeok.com/report`,
                },
                {
                    environment: `beta`,
                    appKey: `yinzhilv-temporary-storage-system`,
                    urlPrefix: `//api-beta.wholeok.com/report`,
                },
                {
                    environment: `release`,
                    appKey: `yinzhilv-temporary-storage-system`,
                    urlPrefix: `//api.wholeok.com/report`,
                },
                {
                    environment: `omega`,
                    appKey: `yinzhilv-temporary-storage-system`,
                    urlPrefix: `//s-omega.yinzhilv.com`,
                },
                //</editor-fold>
                //<editor-fold desc="yinzhilv-report-default">
                {
                    environment: `local`,
                    appKey: `yinzhilv-report-default`,
                    urlPrefix: `//api-beta.wholeok.com/report`,
                },
                {
                    environment: `alpha`,
                    appKey: `yinzhilv-report-default`,
                    urlPrefix: `//api-alpha.wholeok.com/report`,
                },
                {
                    environment: `beta`,
                    appKey: `yinzhilv-report-default`,
                    urlPrefix: `//api-beta.wholeok.com/report`,
                },
                {
                    environment: `release`,
                    appKey: `yinzhilv-report-default`,
                    urlPrefix: `//api.wholeok.com/report`,
                },
                {
                    environment: `omega`,
                    appKey: `yinzhilv-report-default`,
                    urlPrefix: `//s-omega.yinzhilv.com`,
                },
                //</editor-fold>
                //<editor-fold desc="report">
                {
                    environment: `local`,
                    appKey: `report`,
                    urlPrefix: `//api-alpha.wholeok.com/report`,
                },
                {
                    environment: `alpha`,
                    appKey: `report`,
                    urlPrefix: `//api-alpha.wholeok.com/report`,
                },
                {
                    environment: `beta`,
                    appKey: `report`,
                    urlPrefix: `//api-beta.wholeok.com/report`,
                },
                {
                    environment: `release`,
                    appKey: `report`,
                    urlPrefix: `//api.wholeok.com/report`,
                },
                //</editor-fold>
                //<editor-fold desc="dictionary">
                {
                    environment: `local`,
                    appKey: `dictionary`,
                    // urlPrefix: `//api-alpha.wholeok.com/sysManage`,
                    urlPrefix: `//192.168.0.38:8665/sysManage`,
                    // urlPrefix: `//192.168.0.23:8665`,//小龙
                },
                {
                    environment: `alpha`,
                    appKey: `dictionary`,
                    urlPrefix: `//api-alpha.wholeok.com/sysManage`,
                },
                {
                    environment: `beta`,
                    appKey: `dictionary`,
                    urlPrefix: `//api-beta.wholeok.com/sysManage`,
                },
                {
                    environment: `release`,
                    appKey: `dictionary`,
                    // urlPrefix: `//api.wholeok.com/sysManage`,
                    urlPrefix: `//v2-api.wholeok.com/sysManage`,
                },
                {
                    environment: `omega`,
                    appKey: `dictionary`,
                    // urlPrefix: `//api-omega.wholeok.com/sysManage`,
                    urlPrefix: `//v2-api.wholeok.com/sysManage`,
                },
                //</editor-fold>
                //直连连接酒店服务的
                // <editor-fold desc="haolv-hotel">
                {
                    environment: `local`,
                    appKey: `haolv-hotel`,
                    urlPrefix: `//localhost:9090/`,
                },
                {
                    environment: `alpha`,
                    appKey: `haolv-hotel`,
                    urlPrefix: `//hotel-beta.yinzhilv.com`,
                },
                {
                    environment: `beta`,
                    appKey: `haolv-hotel`,

                    urlPrefix: `//hotel-beta.yinzhilv.com`,
                },
                {
                    environment: `omega`,
                    appKey: `haolv-hotel`,
                    urlPrefix: `//hotel-beta.yinzhilv.com`,
                },
                {
                    environment: `release`,
                    appKey: `haolv-hotel`,
                    urlPrefix: `//hotel.yinzhilv.com`,
                }
            ]
        }
    }
});

console.log(dict_app_environment_join);

module.exports = dict_app_environment_join;
